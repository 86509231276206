import React, { type FunctionComponent, useCallback, useState } from 'react';
import { getFacetDisplayValue, getFacetGroupDisplayName } from '../../../../helpers/search-helper/search-helper';
import { useClientOnlyBreakpoints } from '../../../../hooks/breakpoints/client-only-breakpoints.hook';
import { type FacetWithSource, type SelectedFacet, type SelectedFacetGroup } from '../../../../types/search.types';
import { TextButton } from '../../../buttons';
import { CloseIcon } from '../../../svg/icons.component';
import {
	newFilterPill,
	innerContentInnerPadding,
	topAndBottomPaddingOnMobile,
	topAndBottomPaddingOnDesktop
} from './selected-filter-pill.css';

export type SelectedFilterPillProps = {
	facet: SelectedFacet;
	group: SelectedFacetGroup;
	removeFacet: (facet: FacetWithSource) => void;
};

export const SelectedFilterPill: FunctionComponent<SelectedFilterPillProps> = ({ facet, group, removeFacet }) => {
	const facetDisplayValue = getFacetDisplayValue(facet, group);
	const { isNotSmall } = useClientOnlyBreakpoints();
	const [facetGroupNameWidth, setFacetGroupNameWidth] = useState(0);

	const removeClick = () => {
		removeFacet({ id: facet.facetId, value: facet.value, sourceFacetId: facet.sourceFacetId });
	};

	// Calculating width here to make sure element is perfecty centered on pill
	// when the name takes up entire width space
	const facetGroupRef = useCallback(
		(node: HTMLDivElement) => {
			setFacetGroupNameWidth(node && node.offsetWidth + 61);
		},

		[facetGroupNameWidth]
	);

	return (
		<div
			key={facet.facetId}
			className="mt2 ml2 mr1 dib relative"
			// Setting the styles here b/c we need to expand the pill's width
			// when the Facet Group Name (content overlapping the pill line) is greater than
			// the Facet Display Value (content inside the pill) so that it doesn't overflow outside the pill
			style={{
				width: `${getFacetGroupDisplayName(group).length > facetDisplayValue.length + 3 ? facetGroupNameWidth : ''}px`
			}}>
			<div ref={facetGroupRef} className={`${newFilterPill} f7 absolute`}>
				{getFacetGroupDisplayName(group)}
			</div>
			<div
				className={`${innerContentInnerPadding} ba items-center br-pill b--theme-black bg-theme-white mb1 flex justify-between f5 nowrap`}>
				<span className={isNotSmall ? topAndBottomPaddingOnDesktop : topAndBottomPaddingOnMobile}>{facetDisplayValue}</span>
				<div>
					<TextButton onClick={removeClick} color="grey-darker" ariaLabel={`Remove filter: ${group.name}: ${facetDisplayValue}`}>
						<div className="flex items-center pa1 pl2">
							<CloseIcon />
						</div>
					</TextButton>
				</div>
			</div>
		</div>
	);
};
