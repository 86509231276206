import React, { type FunctionComponent } from 'react';
import { SEARCH_RESULT_LIMIT, SEARCH_SORT_OPTIONS_MOBILE, STOCK_STATUS_OPTIONS } from '../../../constants/search';
import { booleanFromQueryString, formatNumber } from '../../../helpers/general-helper/general-helper';
import { convertToProductSortEnum } from '../../../helpers/search-helper/search-product-helper';
import { useSearchResults, type UseSearchResultsPayload } from '../../../hooks/apollo/search/search.hooks';
import { SelectBox, type SelectOptionProps } from '../../inputs/select-box/select-box.component';

export type SearchHeaderV2Props = {
	searchHeaderV2FlagStatus?: PlpNavVariations;
	title?: string;
	sortOptions?: SelectOptionProps[];
	useCategorySort?: boolean;
	showNonstock?: boolean;
	useResults?: () => UseSearchResultsPayload;
};

export enum PlpNavVariations {
	variation1 = 'v1',
	variation2 = 'v2',
	control = 'control'
}

export const SearchHeaderV2: FunctionComponent<SearchHeaderV2Props> = ({
	searchHeaderV2FlagStatus,
	title,
	showNonstock,
	sortOptions = SEARCH_SORT_OPTIONS_MOBILE,
	useResults = useSearchResults
}) => {
	const { results, loading, sortBy, setSortBy, isNonstock, setNonstock } = useResults();
	const count = results?.count || 0;
	let resultCount = formatNumber(Math.min(count, SEARCH_RESULT_LIMIT));
	if (count > SEARCH_RESULT_LIMIT) {
		resultCount = `${resultCount}+`;
	}

	const stockStatusChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const nonstock = booleanFromQueryString(event.target.value);
		setNonstock(nonstock);
	};
	const sortChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const sort = convertToProductSortEnum(event.target.value);
		setSortBy(sort);
	};

	const isV1SearchHeaderActive = searchHeaderV2FlagStatus === PlpNavVariations.variation1;
	const isV2SearchHeaderActive = searchHeaderV2FlagStatus === PlpNavVariations.variation2;

	return isV1SearchHeaderActive || isV2SearchHeaderActive ? (
		<>
			<div className="pv3 w-100 b--theme-grey-light pa1 flex flex-wrap justify-between-ns">
				<div className="w-100 w-auto-ns">
					<h1 className="dib ma0" data-automation="search-header-text">
						{title}
					</h1>
					<div className={`mt1 mt0-l mb1 mb0-l ml2-l dib-l ${loading ? 'o-0' : ''}`} data-automation="results-count">
						{resultCount} Products
					</div>
				</div>
				<span className="flex">
					<span className="dn db-l relative">
						<span className="absolute bg-theme-white f7 ph1" style={{ marginTop: '-0.4rem', marginLeft: '0.9rem' }}>
							Sort
						</span>
						<SelectBox
							name="sortSelect"
							value={sortBy}
							options={sortOptions}
							automationHook="product-sort-by"
							ariaLabel="sort"
							onChange={sortChanged}
							data-testid="sort-select"
							borderStyle="dark"
							className="mb0 dib-l bg-theme-white h-100 flex justify-end"
						/>
					</span>
					{showNonstock && (
						<span className="dn db-l mt0-ns h-100">
							<SelectBox
								name="sortSelect"
								value={String(isNonstock)}
								options={STOCK_STATUS_OPTIONS}
								automationHook="product-stock-status"
								ariaLabel="stock status"
								onChange={stockStatusChanged}
								data-testid="stock-status-select"
								employee={true}
								className="mb0 w-100 h-100 db"
							/>
						</span>
					)}
				</span>
			</div>
		</>
	) : null;
};
