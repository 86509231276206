import React, { type FunctionComponent, type PropsWithChildren, type ReactElement, useEffect, useState } from 'react';
import { type UseFormRegister } from 'react-hook-form';
import { generateDataSelector } from '../../../helpers/general-helper/general-helper';

export type RadioOptionProps =
	| {
			id?: string;
			name: string;
			value: string;
			description?: string | ReactElement;
			disabled?: boolean;
	  }
	| {
			id: string;
			name: string | ReactElement;
			value: string;
			description?: string | ReactElement;
			disabled?: boolean;
	  };

export type RadioButtonGroupProps = {
	groupName: string;
	options: RadioOptionProps[];
	defaultValue?: string;
	defaultSelected?: boolean; // True by default, setting to false will make no radio selected initially
	bold?: boolean;
	btnPadding?: boolean;
	onSelectionChanged?: (selectedOption: string) => void;
	automationHook?: string;
	orientation?: 'horizontal' | 'vertical' | 'responsive';
	className?: string;
	register?: UseFormRegister<any>;
};

export const RadioButtonGroup: FunctionComponent<PropsWithChildren<RadioButtonGroupProps>> = ({
	groupName,
	options,
	defaultValue,
	defaultSelected = true,
	onSelectionChanged,
	bold,
	btnPadding,
	automationHook,
	children,
	orientation = 'vertical',
	className = '',
	register
}) => {
	const [selectedValue, setSelectedValue] = useState<string | null>(defaultSelected ? defaultValue || options[0].value : null);
	const onChange = (value) => {
		setSelectedValue(value);
		if (onSelectionChanged) {
			onSelectionChanged(value);
		}
	};

	useEffect(() => {
		if (defaultSelected && !options.find((option) => option.value === selectedValue)) {
			setSelectedValue(options[0].value);
		}
	}, [options]);

	return (
		<div
			className={`flex ${
				orientation === 'responsive' ? 'flex-row-ns flex-column' : orientation === 'horizontal' ? 'flex-row' : 'flex-column'
			} `}>
			{options.map(({ id, name, value, description, disabled = false }) => (
				<label
					key={value}
					className={`flex pointer ${children ? 'flex-column' : 'items-center'} ${
						orientation === 'responsive' ? ' mr3-ns' : 'mb1'
					}`}
					htmlFor={typeof name === 'string' ? name : id}
					data-automation={generateDataSelector('radiogroup', automationHook)}>
					<span className={`flex ${className} items-start ${btnPadding ? 'pv2' : ''}`}>
						<input
							id={typeof name === 'string' ? name : id}
							type={'radio'}
							name={groupName}
							value={value}
							disabled={disabled}
							checked={selectedValue === value}
							className={'ma0 pointer'}
							onChange={(e) => onChange(e.target.value)}
							style={{ marginTop: 2 }}
							{...register}
						/>
						<span className={`flex flex-column ml2 ${bold ? 'b' : ''}`}>
							{name}
							{description && <span className={'theme-grey f6'}>{description}</span>}
						</span>
					</span>
					{selectedValue === value && children ? <span className={'pa3'}>{children}</span> : null}
				</label>
			))}
		</div>
	);
};
